import React from 'react'
import MRAD from '../assets/MRAD_SS.png';
import MakeItHappen from '../assets/MakeItHappen_SS.png';
import BCRepit from '../assets/BCRepit.png';

export default function ProjectBox() {

    const Project = ({image, title, description, type, link}) => {
        return (
            <div className='flex w-full py-4'>
                {type === 0 ? 
                <a href={link} target="_blank" rel="noopener noreferrer" className='flex w-full justify-center items-center'>
                    <img className="rounded-lg max-h-82 m-2 hover:opacity-50 hover:cursor-pointer" src={image} alt="Project Screenshot" /> 
                </a> 
                :
                <div className='flex flex-col w-full justify-center items-center'>
                    <div className='text-3xl'> 
                        {title}
                    </div>
                    <div className='w-4/6 text-center'> 
                        {description}
                    </div>
                </div>
                }

                {type === 1 ? 
                <a href={link} target="_blank" rel="noopener noreferrer" className='flex w-full justify-center items-center'>
                    <img className="rounded-lg max-h-82 m-2 hover:opacity-50 hover:cursor-pointer" src={image} alt="Project Screenshot" /> 
                </a> 
                :
                <div className='flex flex-col w-full justify-center items-center'>
                    <div className='text-3xl flex-row'> 
                        {title}
                    </div>
                    <div className='w-4/6 text-center'> 
                        {description}
                    </div>
                </div>}
            </div>
        )
    }


    return (
        <div>
            <div className="text-4xl pt-8 pb-6">
                PROJECTS
            </div>
            <div>
                <Project image={MRAD} title={"MRAD Selection Site"} link={"https://github.com/inaguu/comp4800_MRAD"} description={"A web application developed for the Medical Radiography program at BCIT. It was built using TailwindCSS and ExpressJS for front-end and back-end frameworks."} type={0}/>
                <Project image={MakeItHappen} title={"MakeItHappen"} link={"https://github.com/JoushuaDelaCruz/COMP4921_Project3_Group9_Joushua_Winston_Client"} description={"An academic project where individuals and populate custom calendars. Designed to focus on MySQL CRUD operations, individuals can invite colloborators, share events to custom groups, and includes QOL features such as recycle bins and notifications."} type={1}/>
                <Project image={BCRepit} title={"BC Repit"} link={"https://github.com/samueljsong/repit-c"} description={"A web-mobile application designed to allow students to report damaged or malfunctional equipment and view live status updates on their tickets. This project was built using React Vite and tested using Cypress"} type={0}/>
                <Project image={MakeItHappen} title={"Unity Rougelike"} link={"https://github.com/Wontoni/DB_UnityProject_Winston_Ian"} description={"A rougelike Unity project built using OOP principles, allowing for save states, and a smooth gameplay experience."} type={1}/>
            </div>
        </div>
    )
}
