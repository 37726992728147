import Home from "./pages/Home";
import {
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements
} from "react-router-dom";
import "./index.css";

const App = () => {
  const routes = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route index element={<Home/>} />
        <Route
          path="/login"
          element={<Home/>}
        />
        <Route path="*" element={<h1>404</h1>} />
      </Route>
    )
  );

  return <div className="min-h-screen h-full bg-background text-white p-8">
    <RouterProvider router={routes} />
  </div>;
};

export default App;