import React from 'react';
import ProjectBox from '../components/ProjectBox';
import SkillsBox from '../components/SkillsBox';
import NameBox from '../components/NameBox';
import AboutBox from '../components/AboutBox';
function Home() {



  return (
    <div>
        <NameBox />
        <AboutBox />
        <SkillsBox />
        <ProjectBox />
    </div>
  );
}

export default Home;
