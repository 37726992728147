import React, {useEffect} from 'react'
import GitHubLogo from '../assets/github-logo.png';
import LinkedInLogo from '../assets/LinkedIn_icon.png';
import PdfLogo from '../assets/pdf-1.png';
import Resume from '../assets/Winston_Resume_2024.pdf'
import { motion, useAnimate, stagger } from 'framer-motion';

const staggerMenuItems = stagger(0.1, { startDelay: 0.5 });

export default function NameBox() {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        animate(".nameChar",
            {
                opacity: 1,
                y: 0
            }, 
            {
                duration: 1.5,
                delay: staggerMenuItems,
            })
    })

    const Links = () => {
        return (
        <div className="flex py-4">
            <a href="https://github.com/Wontoni" target="_blank" rel="noopener noreferrer" className="flex items-center"> { /* GitHub */}
            <img className="w-14" src={GitHubLogo} alt="GitHub Logo" />
            </a> 
            <a href="https://www.linkedin.com/in/winstonnguyenn" target="_blank" rel="noopener noreferrer" className="flex items-center">{ /* LinkedIn */}
            <img className="w-11 pr-3" src={LinkedInLogo} alt="LinkedIn Logo" />
            </a>
            <a href={Resume} download="Winston_Nguyen_Resume" className="flex items-center">{ /* Resume */}
            <img className="w-16" src={PdfLogo} alt="PDF Logo" />
            <span>Resume</span>
            </a>
        </div>
        );
    };

    const Name = () => {
        return(
            <div className='flex text-9xl' ref={scope}>
                W
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    i
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    n
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    s
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    t
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    o
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    n
                </motion.div>
                <motion.div
                initial={{x: -350}}
                animate={{x: 0}}
                transition={{delay:0.5, duration: 1.0}}
                
                >
                    &nbsp;N
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    g
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    u
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    y
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    e
                </motion.div>
                <motion.div 
                className='nameChar'
                initial={{y: 50, opacity: 0}}
                >
                    n
                </motion.div>
            </div>
        )
    }
    
    return (
        <div>
            <Name />
            <Links/>
        </div>
    );

}
