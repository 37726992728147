import React, {useState, useEffect} from 'react'

export default function SkillsBox() {
    const [activeSkillTab, setActiveSkillTab] = useState('Languages'); // Languages, Frameworks, Databases, Other

    const handleSkillTabClick = (tab) => {
      setActiveSkillTab(tab);
    };
  
    const SkillLevel = ({ level, skill }) => {
      const maxLevel = 4;
      const [filledPercentage, setFilledPercentage] = useState(0);
  
      useEffect(() => {
        const percentage = (level / maxLevel) * 100;
        setFilledPercentage(percentage);
      }, [level]);
  
      const getColor = (level) => {
        if (level <= maxLevel * 0.25) return 'bg-red-500'; // Red for beginner
        if (level <= maxLevel * 0.5) return 'bg-orange-500'; // Orange for intermediate
        if (level <= maxLevel * 0.75) return 'bg-yellow-500'; // Yellow for advanced
        return 'bg-green-500'; // Green for expert
      };
  
      return (
        <div>
          <p className="mb-1">{skill}</p>
          <div className="relative w-full h-2 bg-gray-200 rounded-lg overflow-hidden mb-4">
            <div
              className={`h-full ${getColor(level)} transition-all duration-1000`}
              style={{ width: `${filledPercentage}%` }}
            />
  
            {[...Array(maxLevel)].map((_, index) => (
              <div
                key={index}
                className="absolute inset-y-0 w-px bg-black"
                style={{ left: `${((index + 1) / maxLevel) * 100}%` }}
              />
            ))}
          </div>
        </div>
      );
    };
  
    const LanguageSkills = () => {
      return (
        <div className="p-6">
          <SkillLevel level={4} skill={"JavaScript"} />
          <SkillLevel level={4} skill={"Python"} />
          <SkillLevel level={4} skill={"SQL"} />
          <SkillLevel level={3} skill={"Java"} />
          <SkillLevel level={3} skill={"HTML"} />
          <SkillLevel level={3} skill={"CSS"} />
          <SkillLevel level={3} skill={"C#"} />
          <SkillLevel level={3} skill={"C"} />
        </div>
      );
    };
  
    const FrameworkSkills = () => {
      return (
        <div className="p-6">
          <SkillLevel level={4} skill={"ExpressJS"} />
          <SkillLevel level={4} skill={"NodeJS"} />
          <SkillLevel level={3} skill={"React"} />
          <SkillLevel level={3} skill={"NextJS"} />
          <SkillLevel level={3} skill={"TailwindCSS"} />
        </div>
      );
    };
  
    const DatabaseSkills = () => {
      return (
        <div className="p-6">
          <SkillLevel level={4} skill={"MySQL"} />
          <SkillLevel level={4} skill={"MongoDB"} />
          <SkillLevel level={4} skill={"Google Firebase"} />
          <SkillLevel level={2} skill={"PostgreSQL"} />
          <SkillLevel level={2} skill={"Prisma"} />
        </div>
      );
    };
  
    const OtherSkills = () => {
      return (
        <div className="p-6">
          <SkillLevel level={4} skill={"Agile Development"} />
          <SkillLevel level={4} skill={"Git"} />
          <SkillLevel level={4} skill={"Cypress"} />
          <SkillLevel level={3} skill={"REST APIS"} />
          <SkillLevel level={3} skill={"Unity"} />
          <SkillLevel level={2} skill={"PowerBI"} />
        </div>
      );
    };

    return (
        <div>
            <div className="text-4xl pt-8">
                Skills
            </div>
            <div className="w-fit">
                <div className="flex border-b">
                <button
                    className={`py-2 px-4 transition-colors duration-300 ${
                    activeSkillTab === 'Languages'
                        ? 'border-b-2 border-blue-500 text-blue-500'
                        : 'text-gray-500 hover:text-blue-500'
                    }`}
                    onClick={() => handleSkillTabClick('Languages')}
                >
                    Languages
                </button>
                <button
                    className={`py-2 px-4 transition-colors duration-300 ${
                    activeSkillTab === 'Frameworks'
                        ? 'border-b-2 border-blue-500 text-blue-500'
                        : 'text-gray-500 hover:text-blue-500'
                    }`}
                    onClick={() => handleSkillTabClick('Frameworks')}
                >
                    Frameworks
                </button>
                <button
                    className={`py-2 px-4 transition-colors duration-300 ${
                    activeSkillTab === 'Databases'
                        ? 'border-b-2 border-blue-500 text-blue-500'
                        : 'text-gray-500 hover:text-blue-500'
                    }`}
                    onClick={() => handleSkillTabClick('Databases')}
                >
                    Databases
                </button>
                <button
                    className={`py-2 px-4 transition-colors duration-300 ${
                    activeSkillTab === 'Tools/Other'
                        ? 'border-b-2 border-blue-500 text-blue-500'
                        : 'text-gray-500 hover:text-blue-500'
                    }`}
                    onClick={() => handleSkillTabClick('Tools/Other')}
                >
                    Tools/Other
                </button>
                </div>
                <div className="p-4">
                {activeSkillTab === 'Languages' && <LanguageSkills />}
                {activeSkillTab === 'Frameworks' && <FrameworkSkills />}
                {activeSkillTab === 'Databases' && <DatabaseSkills />}
                {activeSkillTab === 'Tools/Other' && <OtherSkills />}
                </div>
            </div>
        </div>
    );

}
