import React from 'react'

export default function AboutBox() {
    return (
        <div className='w-1/2'>
            <div className="text-4xl">
                About Me
            </div>
            Hey, I'm co-op student at BCIT pursuing a Bachelors in Applied Computer Science (Network Security Applications Development option). I am a prospective software engineer looking for a co-op opportunity for up to 12 months starting January 2025.
        </div>
    );
}
